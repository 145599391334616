import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';

const AssociationCategoryShow = () => {
      const { categoryId } = useParams();

      const [type, setType] = useState([]);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/association_categories/${categoryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setType(response.data.category);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col>
                              <Card>
                                    <CardHeader title="Category Detail">
                                          <LinkButton
                                                link="/dashboard/association_categories"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              type?.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="By"
                                                                        value={
                                                                              type?.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              type?.status ===
                                                                              1 ? (
                                                                                    <label className="text-success">
                                                                                          Active
                                                                                    </label>
                                                                              ) : (
                                                                                    <label className="text-danger">
                                                                                          Disabled
                                                                                    </label>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              type?.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Description"
                                                                        value={
                                                                              <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                          __html: type?.description
                                                                                    }}
                                                                              ></div>
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div className="h-25 text-center">
                                                            <Spinner />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default AssociationCategoryShow;
