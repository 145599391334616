import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CardHeader from '../../components/common/CardHeader';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';

const ShopShow = () => {
      const { shopId } = useParams();
      const [shop, setShop] = useState([]);

      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/shops/${shopId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setShop(response.data.shop);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Shop Detail">
                                          <LinkButton
                                                link="/dashboard/shops"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      {shop?.image && (
                                                            <Col lg={4}>
                                                                  <Card>
                                                                        <Card.Body className="text-center">
                                                                              <img
                                                                                    className="w-100"
                                                                                    src={`${shop.image}`}
                                                                              />
                                                                        </Card.Body>
                                                                  </Card>
                                                            </Col>
                                                      )}
                                                      <Col lg="8">
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Name"
                                                                        value={
                                                                              shop?.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Website"
                                                                        value={
                                                                              shop?.website
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="By "
                                                                        value={
                                                                              shop?.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date "
                                                                        value={
                                                                              shop?.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              shop?.status ? (
                                                                                    <>
                                                                                          <span className="text-success">
                                                                                                Active
                                                                                          </span>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <span className="text-warning">
                                                                                                Disable
                                                                                          </span>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="my-5">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ShopShow;
