import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSelect,
      FormSeo,
      FormStatusSelect,
      FormTextarea
} from '../../components/form';

const CategoryEdit = () => {
      const { categoryId } = useParams();
      const navigate = useNavigate();

      const [item, setItem] = useState('');
      const [currentCategory, setCurrentCategory] = useState('');
      const [subCategory, setSubCategory] = useState('');
      const [childCategory, setChildCategory] = useState('');

      const [slug, setSlug] = useState('');
      const [title, setTitle] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [description, setDescription] = useState('');
      const [status, setStatus] = useState('');
      const [navigationStatus, setNavigationStatus] = useState('');
      const [loading, setLoading] = useState(false);
      const [link, setLink] = useState('');
      const [subtitle, setSubtitle] = useState('');
      const [pdf, setPdf] = useState('');
      const [pdfUrl, setPdfUrl] = useState('');
      const [icon, setIcon] = useState('');

      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [childCategories, setChildCategories] = useState([]);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSlug(response.data.category.slug);
                              setIcon(response.data.category.icon);
                              setTitle(response.data.category.title);
                              setDescription(
                                    response.data.category.description
                              );
                              setStatus(response.data.category.status);
                              setImageUrl(response.data.category.image);
                              setNavigationStatus(
                                    response.data.category.navigation_status
                              );
                              setSeoTitle(response.data.category.seo_title);
                              setSeoKeyword(response.data.category.seo_keyword);
                              setSeoDescription(
                                    response.data.category.seo_description
                              );
                              setSubtitle(response.data.category.subtitle);
                              setLink(response.data.category.link);
                              setPdfUrl(response.data.category.pdf);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}/check`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                              setSubCategories(response.data.subCategories);
                              setChildCategories(response.data.childCategories);
                              setItem(response.data.category);
                              setCurrentCategory(response.data.currentCategory);
                              setSubCategory(response.data.subCategory);
                              setChildCategory(response.data.childCategory);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const loadCategory = async (value) => {
            setCurrentCategory(value);
            setChildCategories([]);
            setItem(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSubCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const loadSubCategory = async (value) => {
            setCurrentCategory(value);
            setSubCategory(value);
            const data = {
                  id: value
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/categories/list`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setChildCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const loadChildCategory = async (value) => {
            setCurrentCategory(value);
            setChildCategory(value);
      };

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  description: description,
                  category: currentCategory || null,
                  image: image,
                  slug: slug,
                  navigation_status: navigationStatus,
                  status: status,
                  seo_title: seoTitle,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription,
                  link: link,
                  subtitle: subtitle,
                  pdf: pdf,
                  icon: icon
            };

            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/categories/${updatedSlug}/edit`);
                  }
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/categories/${categoryId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              updatedSlug = slug;
                              handleUpdateSlug();
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.navigation_status) {
                                    toast.error(
                                          response.data.message
                                                .navigation_status
                                    );
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.link) {
                                    toast.error(response.data.message.link);
                              }
                              if (response.data.message.subtitle) {
                                    toast.error(response.data.message.subtitle);
                              }
                              if (response.data.message.pdf) {
                                    toast.error(response.data.message.pdf);
                              }
                              if (response.data.message.icon) {
                                    toast.error(response.data.message.icon);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Category">
                                                <LinkButton
                                                      link="/dashboard/categories"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            classes={8}
                                                            value={title}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Slug"
                                                            classes={4}
                                                            value={slug}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Subtitle"
                                                            classes={4}
                                                            value={subtitle}
                                                            handleChange={
                                                                  setSubtitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Website"
                                                            classes={4}
                                                            value={link}
                                                            handleChange={
                                                                  setLink
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Home Menu"
                                                            classes={4}
                                                            selected={
                                                                  navigationStatus
                                                            }
                                                            setStatus={
                                                                  setNavigationStatus
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            classes={4}
                                                            selected={item}
                                                            loading={loading}
                                                            setData={
                                                                  loadCategory
                                                            }
                                                            options={categories}
                                                      />
                                                      {subCategories?.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Sub Category"
                                                                  classes={4}
                                                                  selected={
                                                                        subCategory
                                                                  }
                                                                  setData={
                                                                        loadSubCategory
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  options={
                                                                        subCategories
                                                                  }
                                                            />
                                                      )}
                                                      {childCategories?.length >
                                                            0 && (
                                                            <FormSelect
                                                                  title="Child Category"
                                                                  classes={4}
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  selected={
                                                                        childCategory
                                                                  }
                                                                  options={
                                                                        childCategories
                                                                  }
                                                                  setData={
                                                                        loadChildCategory
                                                                  }
                                                            />
                                                      )}
                                                      <FormTextarea
                                                            title="Icon"
                                                            value={icon}
                                                            handleChange={
                                                                  setIcon
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormDescription
                                                            title="Description"
                                                            description={
                                                                  description
                                                            }
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            imageUrl={imageUrl}
                                                      />
                                                      <FormImage
                                                            title="Pdf File"
                                                            setImage={setPdf}
                                                            setImageUrl={
                                                                  setPdfUrl
                                                            }
                                                            imageType="pdf"
                                                            imageUrl={pdfUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                          title={seoTitle}
                                          keyword={seoKeyword}
                                          description={seoDescription}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default CategoryEdit;
