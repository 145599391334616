import React from 'react';
import { Accordion, Row } from 'react-bootstrap';
import MultipleFile from '../../form/MultipleFile';

const ExtraDownloads = ({
      downlaods,
      setDownloadFiles,
      setDownlods,
      productDownloads,
      type,
      setProductDownlaods
}) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                    Downloads File
                              </Accordion.Header>
                              <Accordion.Body>
                                    <Row className="g-3">
                                          <MultipleFile
                                                title="Upload File"
                                                images={downlaods}
                                                grid={4}
                                                classes={12}
                                                type={type}
                                                websiteImages={productDownloads}
                                                setProductImages={setProductDownlaods}
                                                setImages={setDownlods}
                                                setImageFiles={setDownloadFiles}
                                          />
                                    </Row>
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default ExtraDownloads;
