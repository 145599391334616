import React from 'react';
import { Col, Form } from 'react-bootstrap';
import {
      FormGroupBody,
      FormGroupInput,
      FormGroupSection,
      FormGroupUpload,
      FormImageContainer,
      FormImageGallery,
      FormImageRemove
} from './styles';
import { UploadIcon } from '../../icons';

const FormImage = ({
      title,
      required,
      setImage,
      setImageUrl,
      imageUrl,
      grid,
      imageType
}) => {
      const handleImage = (file) => {
            setImage(file);
            if (imageType) {
                  setImageUrl('/images/icons/pdf.png');
            } else {
                  setImageUrl(URL.createObjectURL(file));
            }
      };

      const handleRemoveImage = () => {
            setImage('');
            setImageUrl('');
      };

      return (
            <>
                  <Col lg={4}>
                        <Form.Group>
                              <FormGroupBody>
                                    <Form.Label>
                                          {title}{' '}
                                          {required && (
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          )}
                                    </Form.Label>
                                    <FormGroupSection>
                                          <FormGroupUpload
                                                role="button"
                                                tabIndex="0"
                                          >
                                                <FormGroupInput
                                                      accept={`${
                                                            imageType
                                                                  ? imageType
                                                                  : 'image/*'
                                                      }`}
                                                      onChange={(event) =>
                                                            handleImage(
                                                                  event.target
                                                                        .files[0]
                                                            )
                                                      }
                                                      type="file"
                                                      autoComplete="off"
                                                      tabIndex="-1"
                                                />
                                                <UploadIcon />
                                                <p>
                                                      <span className="text-accent font-semibold">
                                                            Upload an image or
                                                            drag and drop{' '}
                                                      </span>

                                                      <br />
                                                      {imageType ? (
                                                            <span>PDF</span>
                                                      ) : (
                                                            <span>
                                                                  PNG, JPG
                                                            </span>
                                                      )}
                                                </p>
                                          </FormGroupUpload>
                                    </FormGroupSection>

                                    {imageUrl && (
                                          <FormImageGallery
                                                style={{
                                                      gridTemplateColumns:
                                                            grid &&
                                                            `repeat(${grid}, 1fr)`
                                                }}
                                          >
                                                <FormImageContainer>
                                                      <img
                                                            src={`${
                                                                  !imageType
                                                                        ? imageUrl
                                                                        : '/images/icons/pdf.png'
                                                            }`}
                                                      />
                                                      <FormImageRemove
                                                            onClick={() =>
                                                                  handleRemoveImage()
                                                            }
                                                      >
                                                            Remove
                                                      </FormImageRemove>
                                                </FormImageContainer>
                                          </FormImageGallery>
                                    )}
                              </FormGroupBody>
                        </Form.Group>
                  </Col>
            </>
      );
};

export default FormImage;
