import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormImage,
      FormInput,
      FormSelect,
      FormStatusSelect
} from '../../components/form';
import { useParams } from 'react-router-dom';

const AssociationEdit = () => {
      const { associationId } = useParams();
      const [categories, setCategories] = useState([]);
      const [isLoading, setIsLoading] = useState(false);

      const [title, setTitle] = useState('');
      const [category, setCategory] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [link, setLink] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadCategories = async () => {
            setIsLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/associations/${associationId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTitle(response.data.association.title);
                              setCategory(
                                    response.data.association.category_id
                              );
                              setImageUrl(response.data.association.image);
                              setLink(response.data.association.link);
                              setStatus(response.data.association.status);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/associations/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setIsLoading(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  category: category,
                  image: image,
                  link: link,
                  status: status
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/associations/${associationId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.link) {
                                    toast.error(response.data.message.link);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleForm}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Association">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/associations"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                            value={title}
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            classes={4}
                                                            options={categories}
                                                            loading={isLoading}
                                                            required={true}
                                                            setData={
                                                                  setCategory
                                                            }
                                                            selected={category}
                                                      />
                                                      <FormInput
                                                            title="Link"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setLink
                                                            }
                                                            value={link}
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                            selected={status}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default AssociationEdit;
