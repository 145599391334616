import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import CardHeader from '../../components/common/CardHeader';
import { ItemShow } from '../../components/form';
import LinkButton from '../../components/common/LinkButton';

const ConcernShow = () => {
      const { concernId } = useParams();
      const [concern, setConcern] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadCategories = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/concerns/${concernId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setConcern(response.data.concern);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Concern Details">
                                          <LinkButton
                                                link="/dashboard/concerns"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        concern.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Category"
                                                                  value={
                                                                        concern.category
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By"
                                                                  value={
                                                                        concern.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        concern.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        concern.status ===
                                                                        1 ? (
                                                                              <label className="text-success">
                                                                                    Active
                                                                              </label>
                                                                        ) : (
                                                                              <label className="text-danger">
                                                                                    Disabled
                                                                              </label>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: concern.description
                                                                              }}
                                                                        ></div>
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                                <Col lg={6}>
                                                      <div className="d-flex p-3 gap-3">
                                                            {concern.image && (
                                                                  <div>
                                                                        <img
                                                                              style={{
                                                                                    width: '120px'
                                                                              }}
                                                                              src={
                                                                                    concern.image
                                                                              }
                                                                              alt=""
                                                                              className="rounded"
                                                                        />
                                                                  </div>
                                                            )}
                                                      </div>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ConcernShow;
