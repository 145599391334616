import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import { ItemShow } from '../../components/form';

const ProductShow = () => {
      const { productId } = useParams();
      const [product, setProduct] = useState([]);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${productId}/create`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            ).then((response) => {
                  if (response.data.result === 'success') {
                        setProduct(response.data.product);
                  }
            });
      }, []);

      return (
            <>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>
                                                Product Detail
                                          </Card.Title>
                                          <Link
                                                to="/dashboard/products"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          <Row>
                                                <Col md={6}>
                                                      <Card>
                                                            <Card.Body>
                                                                  <div className="text-center">
                                                                        {product.image && (
                                                                              <img
                                                                                    src={`${product.image}`}
                                                                                    className="w-100"
                                                                              />
                                                                        )}
                                                                  </div>
                                                                  <p>
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: product.description
                                                                              }}
                                                                        ></div>
                                                                  </p>
                                                                  {product.specification && (
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: product.specification
                                                                              }}
                                                                        ></div>
                                                                  )}
                                                                  {product.summary && (
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: product.summary
                                                                              }}
                                                                        ></div>
                                                                  )}
                                                                  <ListGroup>
                                                                        <ItemShow
                                                                              title="SEO Title"
                                                                              value={
                                                                                    product.seo_title
                                                                              }
                                                                        />
                                                                        <ItemShow
                                                                              title="SEO Keyword"
                                                                              value={`${product.seo_keyword}`}
                                                                        />
                                                                        <ItemShow
                                                                              title="SEO Description"
                                                                              value={
                                                                                    product.seo_description
                                                                              }
                                                                        />
                                                                  </ListGroup>
                                                                  {product.seo_image && (
                                                                        <div className="p-3">
                                                                              <img
                                                                                    style={{
                                                                                          width: '200px'
                                                                                    }}
                                                                                    src={
                                                                                          product.seo_image
                                                                                    }
                                                                                    alt={
                                                                                          product.title
                                                                                    }
                                                                              />
                                                                        </div>
                                                                  )}
                                                            </Card.Body>
                                                      </Card>
                                                </Col>
                                                <Col md={6}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Name"
                                                                  value={
                                                                        product.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Category"
                                                                  value={
                                                                        product.category
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Brand"
                                                                  value={
                                                                        product.brand_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Installation"
                                                                  value={
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: product.installation
                                                                              }}
                                                                        ></div>
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Warrenty"
                                                                  value={
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: product.warranty
                                                                              }}
                                                                        ></div>
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="By"
                                                                  value={`${product.user_name}`}
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        product.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        product.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProductShow;
