import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';

const BannerShow = () => {
      const { bannerId } = useParams();
      const [banner, setBanner] = useState([]);

      useEffect(() => {
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/banners/${bannerId}`,
                  {
                        headers: {
                              Authorization: `Bearer ${JSON.parse(
                                    localStorage.getItem('token')
                              )}`,
                              'Content-Type': 'multipart/form-data'
                        }
                  }
            )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBanner(response.data.banner);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      }, []);

      const VisitNow = ({ link }) => {
            return (
                  <Link target="_blank" to={link}>
                        Visit Now
                  </Link>
            );
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="banner Details">
                                          <LinkButton
                                                link="/dashboard/banners"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={4}>
                                                      <Card>
                                                            <Card.Body className="text-center">
                                                                  <img
                                                                        src={`${banner.image}`}
                                                                        className="w-100"
                                                                  />
                                                            </Card.Body>
                                                      </Card>
                                                </Col>
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Title"
                                                                  value={
                                                                        banner.title
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Links"
                                                                  value={
                                                                        banner.links && (
                                                                              <VisitNow
                                                                                    link={
                                                                                          banner.links
                                                                                    }
                                                                              />
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Design"
                                                                  value={
                                                                        banner.design
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="User Name"
                                                                  value={
                                                                        banner.user_name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Status"
                                                                  value={
                                                                        banner.status ? (
                                                                              <>
                                                                                    <span className="text-success">
                                                                                          Active
                                                                                    </span>
                                                                              </>
                                                                        ) : (
                                                                              <>
                                                                                    <span className="text-warning">
                                                                                          Disable
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        banner.date
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BannerShow;
